<template>
<!-- Sidebar Wrapper Start -->
<div class="sidebar-wrapper">
    <div class="menu-list">
        <router-link :to="{name:'Dashboard'}" :class="{'active':this.$route.name == 'Dashboard'}"><img src="@/assets/images/menu-icon/icon-3.png" alt="Icon"></router-link>
        <router-link :to="{name:'InstructorCourses'}" :class="{'active':this.$route.name == 'InstructorCourses'}"><img src="@/assets/images/menu-icon/icon-1.png" alt="Icon"></router-link>
        <a href="messages.html"><img src="@/assets/images/menu-icon/icon-2.png" alt="Icon"></a>
       
        <a href="engagement.html"><img src="@/assets/images/menu-icon/icon-4.png" alt="Icon"></a>
        <a href="traffic-conversion.html"><img src="@/assets/images/menu-icon/icon-5.png" alt="Icon"></a>
    </div>
</div>
<!-- Sidebar Wrapper End -->
</template>
<script>
export default{
    name:"Sidebar",
    methods:{}
}

</script>
<style>
.router-link-active .router-link-exact-active{
    background-color: #0044aa;
    border-color: #0044aa;
}
</style>