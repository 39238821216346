<template>

<!-- Courses Item Start -->
<div class="courses-item">
    <div class="item-thumb">
        <a href="#">
            <img :src="course.image" alt="Courses">
        </a>
    </div>

    <div class="content-title">
        <div class="meta">
            <a href="#" class="action">Live</a>
            <a href="#" class="action">Free</a>
            <a href="#" class="action">Public</a>
        </div>
        <h3 class="title"><a href="#">{{ course.name }}</a></h3>
    </div>

    <div class="content-wrapper">

        <div class="content-box">
            <p>Earned</p>
            <span class="count">NGN{{ course.earning }}</span>
        </div>

        <div class="content-box">
            <p>Enrollment’s</p>
            <span class="count">{{ course.activeStundent }}</span>
        </div>

        <div class="content-box">
            <p>Course Rating</p>
            <span class="count">
                    {{ course.rating }}
                    <span class="rating-star">
                        <span class="rating-bar" ref="ratingStar"></span>
            </span>
            </span>
        </div>

        <div class="courses-select">
            <select>
                <option data-display="This Month">This Month</option>
                <option value="1">This Year</option>
                <option value="2">This Week</option>
            </select>
        </div>

    </div>
</div>
<!-- Courses Item End -->
</template>
<script>
export default{
    name:"CourseCard",
    props:['course'],
    mounted(){
        let width = (this.course.rating/5.0) * 100 
        this.$refs.ratingStar.style.width = width + '%' 
    }
}
</script>