<template>
<!-- Login Header Start -->
<div class="section login-header">
    <!-- Login Header Wrapper Start -->
    <div class="login-header-wrapper navbar navbar-expand">

        <!-- Header Logo Start -->
        <div class="login-header-logo">
            <router-link to="/"><img src="@/assets/images/logo-icon.png" alt="Logo"></router-link>
        </div>
        <!-- Header Logo End -->

        <!-- Header Search Start -->
        <div class="login-header-search dropdown">
            <button class="search-toggle" data-bs-toggle="dropdown"><i class="flaticon-loupe"></i></button>

            <div class="search-input dropdown-menu">
                <form action="#">
                    <input type="text" placeholder="Search here">
                </form>
            </div>

        </div>
        <!-- Header Search End -->

        <!-- Header Action Start -->
        <div class="login-header-action ml-auto">
            <div class="dropdown">
                <button class="action notification" data-bs-toggle="dropdown">
                    <i class="flaticon-notification"></i>
                    <span class="active"></span>
                </button>
                <div class="dropdown-menu dropdown-notification">
                    <ul class="notification-items-list">
                        <li class="notification-item">
                            <span class="notify-icon bg-success text-white"><i class="icofont-ui-user"></i></span>
                            <div class="dropdown-body">
                                <a href="#">
                                    <p><strong>Martin</strong> has added a <strong>customer</strong> Successfully
                                    </p>
                                </a>
                            </div>
                            <span class="notify-time">3:20 am</span>
                        </li>
                        <li class="notification-item">
                            <span class="notify-icon bg-success text-white"><i class="icofont-shopping-cart"></i></span>
                            <div class="dropdown-body">
                                <a href="#">
                                    <p><strong>Jennifer</strong> purchased Light Dashboard 2.0.</p>
                                </a>
                            </div>
                            <span class="notify-time">3:20 am</span>
                        </li>
                        <li class="notification-item">
                            <span class="notify-icon bg-danger text-white"><i class="icofont-book-mark"></i></span>
                            <div class="dropdown-body">
                                <a href="#">
                                    <p><strong>Robin</strong> marked a <strong>ticket</strong> as unsolved.
                                    </p>
                                </a>
                            </div>
                            <span class="notify-time">3:20 am</span>
                        </li>
                        <li class="notification-item">
                            <span class="notify-icon bg-success text-white"><i class="icofont-heart-alt"></i></span>
                            <div class="dropdown-body">
                                <a href="#">
                                    <p><strong>David</strong> purchased Light Dashboard 1.0.</p>
                                </a>
                            </div>
                            <span class="notify-time">3:20 am</span>
                        </li>
                        <li class="notification-item">
                            <span class="notify-icon bg-success text-white"><i class="icofont-image"></i></span>
                            <div class="dropdown-body">
                                <a href="#">
                                    <p><strong> James.</strong> has added a<strong>customer</strong> Successfully
                                    </p>
                                </a>
                            </div>
                            <span class="notify-time">3:20 am</span>
                        </li>
                    </ul>
                    <a class="all-notification" href="#">See all notifications <i class="icofont-simple-right"></i></a>
                </div>
            </div>

            <a class="action author" href="#">
                <!-- <img src="@/assets/images/author/author-07.jpg" alt="Author"> -->
            </a>

            <div class="dropdown">
                <button class="action more" data-bs-toggle="dropdown">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <ul class="dropdown-menu">
                    <li><a class="" href="#"><i class="icofont-user"></i> Profile</a></li>
                    <li><a class="" href="#"><i class="icofont-inbox"></i> Inbox</a></li>
                    <li><a class="" href="#"><i class="icofont-logout"></i> Sign Out</a></li>
                </ul>
            </div>
        </div>
        <!-- Header Action End -->

    </div>
    <!-- Login Header Wrapper End -->
</div>
<!-- Login Header End -->

</template>
<script>
export default{
    name:"Header"
}
</script>