<template>
    <Template>
        <!-- Message Start -->
        <div class="message">
            <div class="message-icon">
                <img src="@/assets/images/menu-icon/icon-6.png" alt="">
            </div>
            <div class="message-content">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic.</p>
            </div>
        </div>
        <!-- Message End -->
        
        <!-- Admin Courses Tab Start -->
        <div class="admin-courses-tab">
            <h3 class="title">Courses</h3>

            <div class="courses-tab-wrapper">
                <div class="courses-select">
                    <select>
                        <option data-display="Newest First">Newest First</option>
                        <option value="1">Oldest First</option>
                    </select>
                </div>
                <ul class="nav">
                    <li><button class="active" data-bs-toggle="tab" data-bs-target="#tab1"><i class="icofont-justify-left"></i></button></li>
                    <li><button data-bs-toggle="tab" data-bs-target="#tab2"><i class="icofont-align-left"></i></button></li>
                    <li><button data-bs-toggle="tab" data-bs-target="#tab3"><i class="icofont-eye-blocked"></i></button></li>
                </ul>
                <div class="tab-btn">
                    <a href="#" class="btn btn-primary btn-hover-dark">New Course</a>
                </div>
            </div>
        </div>
        <SingleCourseCard v-for="Course in Courses" :key="Course" :course="Course"/>
        
                <!-- Page Pagination End -->
                <div class="page-pagination">
                    <ul class="pagination justify-content-center">
                        <li><a href="#"><i class="icofont-rounded-left"></i></a></li>
                        <li><a class="active" href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#"><i class="icofont-rounded-right"></i></a></li>
                    </ul>
                </div>
                <!-- Page Pagination End -->
    </Template>    
</template>
<script>
import Template from '@/components/Instructors/Template.vue';
import SingleCourseCard from '@/components/Instructors/SingleCoursesCard.vue'
export default{
    name:"Dashboard",
    data(){
        return{
            Courses:[
                {name:'Data Science and Machine Learning with Python - Hands On!',earning:"6000",activeStundent:"200",category:"Science",authorName:'Jason Williams',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"4.0"},
                {name:'Data Science and Machine Learning with Python - Hands On!',earning:"6000",activeStundent:"200",category:"Science",authorName:'Jason Williams',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"4.0"},
                {name:'Data Science and Machine Learning with Python - Hands On!',earning:"6000",activeStundent:"200",category:"Science",authorName:'Jason Williams',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"5.0"},
                {name:'Data Science and Machine Learning with Python - Hands On!',earning:"6000",activeStundent:"200",category:"Science",authorName:'Jason Williams',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"1.0"},
                {name:'Data Science and Machine Learning with Python - Hands On!',earning:"6000",activeStundent:"200",category:"Science",authorName:'Jason Williams',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"2.0"},
                {name:'Data Science and Machine Learning with Python - Hands On!',earning:"6000",activeStundent:"200",category:"Science",authorName:'Jason Williams',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/577585/pexels-photo-577585.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"3.5"}
            ]
        }
    },
    components:{
        Template,SingleCourseCard
    }

}
</script>