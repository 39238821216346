<template>
    <div class="main-wrapper main-wrapper-02">
        <Header/>
        <div class="section overflow-hidden position-relative" id="wrapper">
            <Sidebar/>
            <div class="page-content-wrapper">
                <div class="container-fluid custom-container">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '@/components/Instructors/Header.vue';
import Sidebar from '@/components/Instructors/Sidebar.vue';
import Footer from '@/components/Footer.vue';
export default{
    name:"Dashboard",
    components:{
        Footer,Header,Sidebar
    }

}
</script>